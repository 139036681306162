import React from "react";
import { AnimatePresence } from "framer-motion";
import { GatsbyBrowser, Script } from "gatsby";

const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ( { element, props } ) => {
    return (
        <>
            <Script src={ `https://www.google.com/recaptcha/api.js?render=6LdELqcZAAAAAOqJO_HWKyH2aMX2shc_SzlP8Ddm` }/>
            <AnimatePresence exitBeforeEnter>
                <div key={ props.location.pathname }>
                    { element }
                </div>
            </AnimatePresence>
            <div id={ "portal__root" }/>
        </>
    )
}

export default wrapPageElement
