export { LayoutContextProvider, useIsMobile, useOpenSidebar, useToggleSidebar, useCloseSidebar, useIsSidebarOpen } from "./LayoutContext"

//-------------------------------------------------------------------------------//
// Create label
//-------------------------------------------------------------------------------//
export const createLabel = ( name: string, operator: string = "-" ) => {
    const addSpace = name.split( operator ).join( " " );
    return addSpace.charAt( 0 ).toUpperCase() + addSpace.substring( 1 ).toLowerCase();
}

export function wrap( min: number, max: number, v: number ) {
    const rangeSize = max - min;
    return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
}
